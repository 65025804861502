import { useForm } from '@rvf/react-router'
import { withZod } from '@rvf/zod'
import { ArrowRight } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { match } from 'ts-pattern'
import { z } from 'zod'
import {
	RVFInput as Input,
	RVFSelect as Select,
	FormSpacer,
	Loader,
} from '#app/components'
import { Button } from '#app/components/ui/button.tsx'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from '#app/components/ui/dialog.tsx'
import { ROUTE_PATHS } from '#app/const/routes.ts'
import { capitalize } from '#app/utils/index.ts'

const joinWaitlistButtonStyle =
	'bg-empirium-teal hover:bg-empirium-teal/90 rounded-md px-8 py-6 text-white shadow-md transition-all hover:translate-y-[-2px] hover:shadow-lg'

const formId = 'createSupplier'

export const validator = withZod(
	z.object({
		companyName: z.string().min(1, 'Please provide a company name'),
		companyWebsite: z.string().min(1, 'Please provide your company website'),
		contactFirstName: z.string().min(1, 'Please provide your first name'),
		contactLastName: z.string().min(1, 'Please provide your last name'),
		contactTitle: z.string().min(1, 'Please provide your title'),
		contactEmail: z.string().email('Invalid email address'),
		contactPhone: z
			.string()
			.refine((v: string) => {
				const check = new RegExp(
					/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
				)
				if (v) {
					return check.test(v)
				} else {
					return true
				}
			}, 'Please provide a valid phone number')
			.optional(),
		customerType: z.enum(['Buyer', 'Supplier', 'Both'], {
			message: 'Please select your customer type',
		}),
		waitlistOrDemo: z.string(),
	}),
)

export const JoinWaitlist = ({
	defaultOpen = false,
	intent = 'join-waitlist',
}: {
	defaultOpen?: boolean
	intent: 'join-waitlist' | 'request-demo'
}) => {
	const [open, setOpen] = useState(false)
	const [state, setState] = useState<'idle' | 'success' | 'error'>('idle')
	const form = useForm({
		id: formId,
		validator,
		action: '/?index',
		method: 'post',
		defaultValues: {
			companyName: '',
			companyWebsite: '',
			contactFirstName: '',
			contactLastName: '',
			contactTitle: '',
			contactPhone: '',
			contactEmail: '',
			customerType: '',
			waitlistOrDemo: intent === 'join-waitlist' ? 'waitlist' : 'demo',
		},
		onSubmitSuccess: () => setState('success'),
		onSubmitFailure: () => setState('error'),
	})

	return (
		<Dialog defaultOpen={defaultOpen} open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				{intent === 'join-waitlist' ? (
					<Button className={joinWaitlistButtonStyle}>
						{capitalize(intent)}
					</Button>
				) : (
					<Button
						variant="outline"
						className="group rounded-md border-empirium-teal px-8 py-6 text-empirium-teal hover:bg-empirium-teal/10"
					>
						Request Demo
						<ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
					</Button>
				)}
			</DialogTrigger>
			<DialogContent
				className="max-h-96 overflow-y-scroll md:max-h-fit"
				aria-describedby={undefined}
			>
				<DialogTitle className="hidden">{capitalize(intent)}</DialogTitle>
				{match(state)
					.with('idle', () => (
						<form
							{...form.getFormProps()}
							className="col-start-1 row-start-1 space-y-8"
						>
							<DialogTitle className="font-michroma text-xl md:text-2xl">
								{capitalize(intent)}
							</DialogTitle>
							<HoneypotInputs />
							<FormSpacer>
								<input
									className="hidden"
									name="waitlistOrDemo"
									readOnly
									value={intent === 'join-waitlist' ? 'waitlist' : 'demo'}
								/>
								<Input name="companyName" label="Company" form={form} />
								<Input name="companyWebsite" label="Website" form={form} />
							</FormSpacer>

							<Select
								name="customerType"
								label="Type"
								options={[
									{ value: 'Buyer' },
									{ value: 'Supplier' },
									{ value: 'Both' },
								]}
								className="w-full"
								form={form}
							/>

							<FormSpacer>
								<Input name="contactFirstName" label="First Name" form={form} />
								<Input name="contactLastName" label="Last Name" form={form} />
							</FormSpacer>
							<FormSpacer>
								<Input name="contactTitle" label="Title" form={form} />
								<Input name="contactPhone" label="Phone" form={form} />
							</FormSpacer>
							<Input name="contactEmail" label="Email" form={form} />
							<Button
								type="submit"
								disabled={form.formState.hasBeenSubmitted}
								size="lg"
								className={`${joinWaitlistButtonStyle} w-full`}
							>
								{form.formState.hasBeenSubmitted ? (
									<div className="flex items-center gap-2">
										Submitting... <Loader className="size-4" />
									</div>
								) : (
									'Submit'
								)}
							</Button>
						</form>
					))
					.with('success', () => (
						<div className="col-start-1 row-start-1 space-y-8 pt-6 text-center">
							<h2 className="font-michroma text-2xl">
								Thank you for your interest in Empirium!
							</h2>
							<p className="text-lg">
								We're building a one-stop shop for critical goods and services,
								connecting trusted buyers and sellers worldwide.
							</p>
							<p>
								We'll keep you updated as we build. In the meantime, feel free
								to contact us at{' '}
								<Link to="mailto:info@empirium.co" className="text-blue-500">
									info@empirium.co
								</Link>{' '}
								if you'd like to learn more.
							</p>
							<Button
								className={`${joinWaitlistButtonStyle} w-full`}
								onClick={() => setOpen(false)}
							>
								Return Home
							</Button>
						</div>
					))
					// TODO: If we want to handle submission errors, we can do so here
					.with('error', () => (
						<div className="col-start-1 row-start-1 space-y-8 text-center">
							<h2 className="font-michroma text-4xl">
								Thank you for your interest in Empirium!
							</h2>
							<p className="text-lg">
								We're building a one-stop shop for critical goods and services,
								connecting trusted buyers and sellers worldwide.
							</p>
							<p>
								We'll keep you updated as we build. In the meantime, feel free
								to contact us at{' '}
								<Link to="mailto:info@empirium.co" className="text-blue-500">
									info@empirium.co
								</Link>{' '}
								if you'd like to learn more.
							</p>
							<Link to={ROUTE_PATHS.MARKETING} className="inline-block w-full">
								<Button className={`${joinWaitlistButtonStyle} w-full`}>
									Return Home
								</Button>
							</Link>
						</div>
					))
					.exhaustive()}
			</DialogContent>
		</Dialog>
	)
}
